import React, { useEffect, useState } from "react"
import styled from "styled-components"
import slugify from "../lib/slugify"

import InfoPill from "./InfoPill"

const ExerciseEmbed = ({
  id,
  name,
  cues,
  level,
  bilateral,
  equipment,
  video_url,
  thumbnail_url,
  type,
  target,
  category,
  volume_bound_upper,
  volume_bound_lower,
  volume_unit,
  intensity_multiplier,
  inIframe,
}) => {
  const volumeRange = volume_bound_upper - volume_bound_lower
  const [isFullscreen, setIsFullscreen] = useState(false)

  useEffect(() => {
    const video = document.getElementById(`exercise-video-${id}`)
    video.muted = true
    video.autoplay = true
    video.loop = true
    video.setAttribute("playsinline", true)

    document.addEventListener("fullscreenchange", event => {
      setIsFullscreen(curr => !curr)
    })
  }, [id])

  return (
    <Container>
      <Header target="_blank" href={`https://programme.app/exercises/${slugify(name)}/${id}`}>
        <h2>{name}</h2>
        <div>
          <InfoPill first>{type}</InfoPill>
          <InfoPill>{target}</InfoPill>
          <InfoPill last>{category || "Not Set"}</InfoPill>
        </div>
      </Header>
      <Video
        id={`exercise-video-${id}`}
        isFullscreen={isFullscreen}
        inIframe={inIframe}
        onClick={() => {
          document.getElementById(`exercise-video-${id}`).requestFullscreen()
        }}
        poster={thumbnail_url}
        controlsList="nodownload"
      >
        <source src={video_url} type="video/mp4" />
      </Video>
      <Footer>
        <div>
          <h5>Coaching Tips</h5>
          <p>
            {cues?.split(". ").map(cue => (
              <span key={`${cue}`}>
                • {cue}
                <br />
              </span>
            ))}
          </p>
          {/* <h5>Attributes</h5> */}
          {/* <Attribute */}
          {/*   title="Movement Type" */}
          {/*   body={type} */}
          {/*   link={`https://programme.app/movements/${type */}
          {/*     .replace(/ /g, "-") */}
          {/*     .toLowerCase()}`} */}
          {/* /> */}
          {/* <Attribute */}
          {/*   title="Movement Target" */}
          {/*   body={target} */}
          {/*   link={`https://programme.app/movements/${target */}
          {/*     .replace(/ /g, "-") */}
          {/*     .toLowerCase()}`} */}
          {/* /> */}
          {/* <Attribute */}
          {/*   title="Movement Category" */}
          {/*   body={category || "Not Set"} */}
          {/*   link={`https://programme.app/movements/${category */}
          {/*     ?.replace(/ /g, "-") */}
          {/*     .toLowerCase()}`} */}
          {/* /> */}
          {/* <Attribute title="Bilateral" body={bilateral ? "True" : "False"} /> */}
          {/* <Attribute */}
          {/*   title="Difficulty" */}
          {/*   body={ */}
          {/*     level > 2 ? (level > 3 ? "Advanced" : "Intermediate") : "Beginner" */}
          {/*   } */}
          {/* /> */}
        </div>
        <div>
          {equipment?.length ? (
            <div style={{ marginBottom: 30 }}>
              <h5>Equipment Needed</h5>
              {equipment.map(item => `${item.name} `)}
            </div>
          ) : null}
          <h5>Volume</h5>
          <table>
            <tbody>
              <tr>
                <td>Beginner</td>
                <td>
                  {volume_bound_lower}
                  {volume_unit === "s" && "s"}
                  {volume_unit === "m" && "m"}
                  {volume_unit === "x" && " Reps"}
                </td>
                <td>3 Sets</td>
              </tr>
              <tr>
                <td>Intermediate</td>
                <td>
                  {Math.ceil((volume_bound_lower + volumeRange * 0.4) / 2) * 2}
                  {volume_unit === "s" && "s"}
                  {volume_unit === "m" && "m"}
                  {volume_unit === "x" && " Reps"}
                </td>
                <td>4 Sets</td>
              </tr>
              <tr>
                <td>Advanced</td>
                <td>
                  {Math.ceil((volume_bound_lower + volumeRange * 0.8) / 2) * 2}
                  {volume_unit === "s" && "s"}
                  {volume_unit === "m" && "m"}
                  {volume_unit === "x" && " Reps"}
                </td>
                <td>4 Sets</td>
              </tr>
            </tbody>
          </table>
          {intensity_multiplier ? (
            <div>
              <h5>Intensity</h5>
              <table>
                <tbody>
                  <tr>
                    <td>Beginner</td>
                    <td>
                      {Math.ceil((intensity_multiplier * 1 * 2.2) / 5) * 5}lbs
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Intermediate</td>
                    <td>
                      {Math.ceil((intensity_multiplier * 2.5 * 2.2) / 5) * 5}lbs
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Advanced</td>
                    <td>
                      {Math.ceil((intensity_multiplier * 4 * 2.2) / 5) * 5}lbs
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  padding: 0;
`

const Header = styled.a`
  display: block;
  padding: 20px;
  text-decoration: none !important;
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    color: black;
    margin-bottom: 8px;
  }
`

const Video = styled.video`
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: ${({ isFullscreen, inIframe }) =>
    isFullscreen || inIframe
      ? "pointer"
      : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiByeD0iMjAiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuOCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjMwNDcgMTEuMzA0MkgxNy4xMDEyVjEzLjMwNDJIMTMuMzA0N1YxNy4xMDE0SDExLjMwNDdWMTMuMzA0MlYxMS4zMDQySDEzLjMwNDdaTTExLjMwNDcgMjIuODk4NVYyNi42OTU1VjI4LjY5NTVIMTMuMzA0N0gxNy4xMDEyVjI2LjY5NTVIMTMuMzA0N1YyMi44OTg1SDExLjMwNDdaTTIyLjg5ODMgMjguNjk1NVYyNi42OTU1SDI2LjY5NlYyMi44OTg1SDI4LjY5NlYyNi42OTU1VjI4LjY5NTVIMjYuNjk2SDIyLjg5ODNaTTI4LjY5NiAxNy4xMDE0VjEzLjMwNDJWMTEuMzA0MkgyNi42OTZIMjIuODk4M1YxMy4zMDQySDI2LjY5NlYxNy4xMDE0SDI4LjY5NloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo='), auto;"}
  transition: 0.15s all;

  &:hover {
    opacity: 90%;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0 20px;
  h5 {
    margin-bottom: 5px;
    font-size: 15px;
    color: #777;
  }
  & > div:first-child {
    border-right: 1px solid #eee;
  }
  & > div {
    padding: 0 20px;
    flex-grow: 1;
    width: 50%;
  }
  table {
    margin-left: -5px;
    margin-bottom: 30px;
    width: calc(100% +10px);
    border: none;
    tr {
      height: 24px;
      color: black;
      line-height: 16px;
      border: 0;
      border-radius: 3px;
    }
    td {
      width: 30%;
      font-size: 16px !important;
    }
    td:first-child {
      width: 40%;
      color: #333;
    }
    tr:nth-child(2n) {
      td {
        background: #eee;
      }
      td:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      td:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    td {
      padding: 5px;
      border: none;
    }
  }
  table:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    & > div:first-child {
      border-right: 0;
    }
  }
`

export default ExerciseEmbed
